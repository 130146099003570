import { css } from "emotion"

import axios from "axios"
import qs from "qs"

import * as auth from "../services/Auth/auth"
import env from "../services/Auth/env"

export const getTextColor = (auditResult, auditIssues) => {
  const redText = css({ color: "red" })
  const greenText = css({ color: "green" })
  const yellowText = css({ color: "#DCB700" })

  if (auditResult === "Approved" || auditResult === "Partially Approve") {
    return greenText
  } else if (
    auditResult === "UnApprove" &&
    auditIssues.some(
      issue => issue.blocker === "Y" && issue.status !== "resolved"
    )
  ) {
    return redText
  } else {
    return yellowText
  }
}

export const retrieveData = (query, apiPath, callback) => {
  auth.getToken().then(cookie => {
    axios(env.API_ENDPOINT + apiPath + "?" + qs.stringify(query), {
      method: "GET",
      headers: { idToken: cookie },
    })
      .then(response => {
        // const data = response.data.complianceStatusList
        callback && callback(response?.data)
      })
      .catch(error => {})
  })
}

export const updateData = (payload, apiPath, callback) => {
  auth.getToken().then(cookie => {
    axios(env.API_ENDPOINT + apiPath, {
      method: "POST",
      data: payload,
      headers: { idToken: cookie },
    })
      .then(response => {
        // const data = response.data.complianceStatusList
        callback && callback(response?.data)
      })
      .catch(error => {})
  })
}

export const getUrlParams = search => {
  const hashes = search.slice(search.indexOf("?") + 1).split("&")
  const params = {}
  // eslint-disable-next-line array-callback-return
  hashes.map(hash => {
    const [key, val] = hash.split("=")
    params[key] = decodeURIComponent(val)
  })
  return params
}
export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
export const create_UUID = () => {
  var dt = new Date().getTime()
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
    c
  ) {
    var r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}
export const formValidation = state => {
  let {
    formFields,
    reporterEmailOptionsSelected,
    reviewerEmailOptionsSelected,
    cmContactsEmailOptionsSelected,
    errorFields,
    validationFields,
  } = state
  for (const [key, value] of Object.entries(formFields)) {
    if (validationFields.indexOf(key) === -1) {
      continue
    }
    if (key !== "previousGen") {
      if (Array.isArray(value) && value.length > 0) {
        errorFields.delete(key)
      } else if (
        typeof value !== "undefined" &&
        !Array.isArray(value) &&
        value.trim() &&
        value.length > 0
      ) {
        errorFields.delete(key)
      } else {
        errorFields.add(key)
      }
    }
  }

  if (
    reporterEmailOptionsSelected.length <= 0 ||
    !isValidEmailArray(reporterEmailOptionsSelected)
  ) {
    errorFields.add("reporter")
  } else {
    errorFields.delete("reporter")
  }
  if (
    reviewerEmailOptionsSelected.length <= 0 ||
    !isValidEmailArray(reviewerEmailOptionsSelected)
  ) {
    errorFields.add("reviewer")
  } else {
    errorFields.delete("reviewer")
  }
  if (
    cmContactsEmailOptionsSelected.length <= 0 ||
    !isValidEmailArray(cmContactsEmailOptionsSelected)
  ) {
    errorFields.add("cmContacts")
  } else {
    errorFields.delete("cmContacts")
  }
  return errorFields
}

export const isValidEmailArray = emailArray => {
  // this for loop checks if there are any invalid emails
  for (let i = 0; i < emailArray.length; i++) {
    let email = emailArray[i]
    if (email.isvalid === "false") {
      return false
    }
  }
  return true
}

export const isValidEmail = email => {
  let mailformat = /^[A-Za-z0-9]+([.-]?\w+)*@(amazon|lab126)\.com$/
  if (mailformat.test(email)) {
    return "true"
  } else {
    return "false"
  }
}

export function formatDate(date, fmt) {
  if (typeof date == "string") {
    return date
  }

  if (!fmt) fmt = "yyyy-MM-dd hh:mm:ss"

  if (!date || date == null) return null
  var o = {
    "M+": date.getMonth() + 1, // month
    "d+": date.getDate(), // day
    "h+": date.getHours(), // hour
    "m+": date.getMinutes(), // minute
    "s+": date.getSeconds(), // second
    "q+": Math.floor((date.getMonth() + 3) / 3), // quarter
    S: date.getMilliseconds(), // ms
  }
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    )
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      )
  }
  return fmt
}

// extract from dataTable function <_fnFilterCreateSearch>
// https://github.com/DataTables/DataTables/blob/master/media/js/jquery.dataTables.js
export function getRegex(needle) {
  let temp = needle.match(/"[^"]+"|[^ ]+/g) || [""]
  let words = temp.map(word => {
    if (word.charAt(0) === '"') {
      let m = word.match(/^"(.*)"$/)
      word = m ? m[1] : word
    }

    return escapeRegExp(word.replace('"', ""))
  })

  let regex = "^(?=.*?" + words.join(")(?=.*?") + ").*$"

  return new RegExp(regex, "i")
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") // $& means the whole matched string
}

/**
 * This file contains some string manipulation
 * utilities that are used within Firefly.
 * We've written them in vanilla JS to avoid
 * adding to the bundle size by including a utility
 * library such as Lodash, which is also a solid
 * choice if you find yourself using many of its
 * including methods.
 */

// Convert the first character of a string to
// uppercase.
export const upperFirst = s => {
  if (typeof s !== "string") return ""
  return s.charAt(0).toUpperCase() + s.slice(1)
}

// Convert a string to camelCase, remove any slashes
export const camelCase = str =>
  str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (ltr, idx) =>
      idx === 0 ? ltr.toLowerCase() : ltr.toUpperCase()
    )
    .replace(/\s+/g, "")
    .replace(/\//g, "")

// Convert a string to snake_case
export const snakeCase = str =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join("_")

export const getLocationsByCmName = (cms, cmName) => {
  if (cms.length > 0 && cmName) {
    return cms.filter(c => c.cm === cmName)[0].cmLocations
  }
  return []
}

export function getMonthName(monthNumber) {
  const date = new Date()
  date.setMonth(monthNumber - 1) // starts with 0, so 0 is January
  return date.toLocaleString("en-EN", { month: "short" })
}

export function getYearName(yearNumber) {
  const date = new Date()
  date.setFullYear(yearNumber) // starts with 0, so 0 is January
  return date.toLocaleString("en-EN", { year: "2-digit" })
}

export function sortByQuarterData(data) {
  const sortedData = Object.keys(data).sort((a, b) => {
    const [quarterA, yearA] = a.split("-")
    const [quarterB, yearB] = b.split("-")

    // Function to convert quarter to numerical representation
    const quarterToNumber = quarter => {
      const quarters = { Q1: 1, Q2: 2, Q3: 3, Q4: 4 }
      return quarters[quarter]
    }

    // Compare years first
    if (yearA !== yearB) {
      return yearA - yearB
    }

    // If years are the same, compare quarters
    return quarterToNumber(quarterA) - quarterToNumber(quarterB)
  })

  const sortedObject = {}
  sortedData.forEach(key => {
    sortedObject[key] = data[key]
  })

  console.log(sortedObject)
  return sortedObject
}

export const sortByMonthData = data => {
  const sortedData = Object.keys(data).sort((a, b) => {
    const [monthA, yearA] = a.split("-")
    const [monthB, yearB] = b.split("-")

    // Convert month abbreviation to its corresponding number
    const months = {
      Jan: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11,
    }

    // Compare years first
    if (yearA !== yearB) {
      return yearA - yearB
    }

    // If years are the same, compare months
    return months[monthA] - months[monthB]
  })

  const sortedObject = {}
  sortedData.forEach(key => {
    sortedObject[key] = data[key]
  })
  return sortedObject
}

export const transformChartData = (
  data,
  isSortByMonth,
  isSortByQuarter,
  sampling = true
) => {
  let transformedData = []
  let vals = {}
  if (isSortByMonth === true) {
    data = sortByMonthData(data)
  }
  if (isSortByQuarter === true) {
    data = sortByQuarterData(data)
  }

  //   Uploaded File Chart
  let sampledData = Object.entries(data)
  if (sampling) {
    sampledData = samplingData(Object.entries(data))
  }

  sampledData.map(([key, value]) => {
    Object.entries(value).map(([cat_key, cat_value]) => {
      if (Object.hasOwn(vals, cat_key) === false) {
        vals[cat_key] = {}
      }
      vals[cat_key][key] = cat_value
    })
  })

  Object.entries(vals).map(([key, value]) => {
    transformedData.push({
      key: key,
      label: key,
      data: Object.entries(value).map(([loc_key, loc_value]) => {
        return {
          cm: loc_key,
          value: loc_value,
        }
      }),
    })
  })
  return transformedData
}

export const samplingData = (data, maxNum = 6) => {
  return data.slice(Math.max(data.length - maxNum, 0))
  // return data.filter((x, index) => {
  //   const len = data.length
  //   const ratio = Math.ceil(len / maxNum)
  //   return (index + 1) % ratio === 0
  // })
}

export const showNotification = () => {
  const cookies = document.cookie.split("; ")
  const lastAccessedCookie = cookies.find(cookie =>
    cookie.startsWith("lastAccessed=")
  )
  let isShowNotification = false
  if (lastAccessedCookie) {
    const lastAccessedDateString = lastAccessedCookie.split("=")[1]
    const lastAccessedDate = new Date(lastAccessedDateString)
    const today = new Date()
    isShowNotification = !(
      lastAccessedDate.toDateString() === today.toDateString()
    )
  } else isShowNotification = true // No last accessed date cookie found

  const now = new Date()

  let cookieTimeout = new Date()
  cookieTimeout.setTime(now.getTime() + 7 * 24 * 60 * 60 * 1000)

  document.cookie = `lastAccessed=${now.toUTCString()}; expires=${cookieTimeout.toUTCString()}; path=/`

  return isShowNotification
}

export const getDefaultPerformanceSummaryDate = () => {
  return [
    new Date(
      new Date().getFullYear() - 1,
      new Date().getMonth(),
      new Date().getDate()
    )
      .toISOString()
      .slice(0, 10),
    new Date().toISOString().slice(0, 10),
  ]
}

export const getMonthIndex = monthName => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  return months.indexOf(monthName) + 1
}
