import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Column from "@amzn/meridian/column"
import Row from "@amzn/meridian/row"
import Icon from "@amzn/meridian/icon"
import Box from "@amzn/meridian/box"
import Heading from "@amzn/meridian/heading"
import Divider from "@amzn/meridian/divider"
import Text from "@amzn/meridian/text"
import Table, {
  TableRow,
  TableCell,
  TableSectionHeader,
} from "@amzn/meridian/table"
import Button from "@amzn/meridian/button"
import {
  retrievePerformanceSummaryBySystemData,
  retrievePerformanceSummaryBySystemHeader,
} from "../../../redux/performanceSummary/action"
import PageLayout from "../../../components/app/page-layout"
import KPICard from "../components/KPICard"
import PerformanceLineChart from "../charts/PerformanceLineChart"
import PerformanceBarChart from "../charts/PerformanceBarChart"
import { getMonthIndex, getMonthName, getYearName } from "../../../util/Util"

const PerformanceSystem = props => {
  const [summaryBySystemData, setSummaryBySystemData] = useState({
    clickedItems: [],
    topUserByTeam: [],
    clickByPageBar: {
      key: "page",
      label: "Clicks",
      data: [],
    },
    clicksByMonthLine: [],
  })

  const {
    isLoading,
    summary_by_system_header,
    summary_by_system_data,
  } = useSelector(state => state.performanceReducer)
  const headerColumnWidth = 250

  const reduxDispatch = useDispatch()

  useEffect(() => {
    reduxDispatch(retrievePerformanceSummaryBySystemHeader())
    reduxDispatch(retrievePerformanceSummaryBySystemData())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let newSummaryBySystemData = {
      clickedItems: [],
      topUserByTeam: [],
      clickByPageBar: {
        key: "page",
        label: "Clicks",
        data: [],
      },
      clicksByMonthLine: [
        {
          key: "Dates",
          label: "Clicks",
          data: [],
        },
      ],
    }

    if (summary_by_system_data.topClickedItem != null) {
      newSummaryBySystemData.clickedItems = Object.entries(
        summary_by_system_data.topClickedItem
      ).map(value => [value[0], value[1]])
    }

    if (summary_by_system_data.topUserClickItem != null) {
      Object.entries(summary_by_system_data.topUserClickItem).forEach(
        keyVal => {
          newSummaryBySystemData.topUserByTeam.push(
            ...Object.entries(keyVal[1]).map((values, index) => {
              if (index === 0) return [keyVal[0], ...values]
              return ["", ...values]
            })
          )
        }
      )

      if (summary_by_system_data.totalClicksByPage != null) {
        newSummaryBySystemData.clickByPageBar.data = Object.entries(
          summary_by_system_data.totalClicksByPage
        ).map(value => {
          return {
            page: value[0],
            clicks: value[1],
          }
        })
      }
    }

    if (summary_by_system_data.totalClicksByMonth != null) {
      newSummaryBySystemData.clicksByMonthLine[0].data = Object.entries(
        summary_by_system_data.totalClicksByMonth
      )
        .map(value => {
          const dateSplit = value[0].split("-")
          return {
            date: `${getMonthName(dateSplit[0])}-${getYearName(dateSplit[1])}`,
            value: value[1],
          }
        })
        .sort((a, b) => {
          const [monthA, yearA] = a.date.split("-")
          const [monthB, yearB] = b.date.split("-")

          const monthIndexA = getMonthIndex(monthA)
          const monthIndexB = getMonthIndex(monthB)

          // First compare years, then months
          if (yearA !== yearB) {
            return yearA - yearB
          }
          return monthIndexA - monthIndexB
        });
      console.log(newSummaryBySystemData.clicksByMonthLine[0].data)
    }

    setSummaryBySystemData(newSummaryBySystemData)
  }, [summary_by_system_data])

  return (
    <PageLayout
      spacing="large"
      alignmentHorizontal={"start"}
      loading={isLoading}
      width="100%"
    >
      <div role="tabpanel" id="tabpanel-planets" aria-labelledby="tab-planets">
        {Object.keys(summary_by_system_header).length > 0 && (
          <Column spacing="500">
            <Row>
              <Column>
                <KPICard
                  kpi={summary_by_system_header.totalVisits}
                  title={"Total Visit"}
                  height={180}
                />
              </Column>
              <Column>
                <KPICard
                  kpi={summary_by_system_header.totalUsers}
                  title={"Total Users"}
                  height={180}
                />
              </Column>
              <Column>
                <Row>
                  <KPICard
                    height={82}
                    isHalfHeight
                    kpi={`${summary_by_system_header.activeUsersThisMonth} %`}
                    trend={
                      summary_by_system_header.activeUsersThisMonth > 0
                        ? "ascending"
                        : summary_by_system_header.activeUsersThisMonth < 0
                        ? "descending"
                        : ""
                    }
                    title={"Active User this Month"}
                  />
                </Row>
                <Row>
                  <KPICard
                    height={82}
                    isHalfHeight
                    kpi={`${Math.round(
                      summary_by_system_header.visitThisMonth
                    )} %`}
                    trend={
                      summary_by_system_header.visitThisMonth > 0
                        ? "ascending"
                        : summary_by_system_header.visitThisMonth < 0
                        ? "descending"
                        : ""
                    }
                    title={"Visit This Month"}
                  />
                </Row>
              </Column>
            </Row>
            <Row wrap="down" spacing="500" alignmentVertical="top">
              {summaryBySystemData.clickedItems.length > 0 && (
                <Box spacingInset="300" type="outline" width={500} height={454}>
                  <Heading type="h200"> TOP 3 Clicked Item </Heading>
                  <Divider />
                  <Table showDividers={true} headerRows={1}>
                    <TableRow>
                      <TableCell width={headerColumnWidth}>Top3</TableCell>
                      <TableCell>Clicks</TableCell>
                    </TableRow>
                    {summaryBySystemData.clickedItems.map((value, index) => (
                      <TableRow key={index}>
                        <TableCell width={headerColumnWidth}>
                          {value[0]}
                        </TableCell>
                        <TableCell width={headerColumnWidth}>
                          {value[1]}
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Box>
              )}
              <Box>
                {Object.keys(summaryBySystemData.topUserByTeam).length > 0 && (
                  <Box spacingInset="300" type="outline" width={500}>
                    <Heading type="h200">TOP 3 Users</Heading>
                    <Divider />
                    <Table showDividers={true} headerRows={1} spacing={"small"}>
                      <TableRow backgroundColor="secondary">
                        <TableCell>Team</TableCell>
                        <TableCell>Top3 User</TableCell>
                        <TableCell>Clicks</TableCell>
                      </TableRow>
                      {summaryBySystemData.topUserByTeam.map((key, index) => (
                        <TableRow key={index}>
                          <TableCell>{key[0]}</TableCell>
                          <TableCell>{key[1]}</TableCell>
                          <TableCell>{key[2]}</TableCell>
                        </TableRow>
                      ))}
                    </Table>
                  </Box>
                )}
              </Box>
              {summaryBySystemData.clickByPageBar.data.length > 0 && (
                <PerformanceBarChart
                  data={summaryBySystemData.clickByPageBar}
                  title={"Total Clicks by Page"}
                  xValue={"page"}
                  yValue={"clicks"}
                />
              )}
              {summaryBySystemData.clicksByMonthLine.length > 0 && (
                <PerformanceLineChart
                  data={summaryBySystemData.clicksByMonthLine}
                  title={"Total Clicks by Month"}
                  xValue={"date"}
                  yValue={"value"}
                  tickFormat={t => `${Math.round(t / 1000)}K`}
                />
              )}
            </Row>
          </Column>
        )}
      </div>
    </PageLayout>
  )
}

export default PerformanceSystem
